/* eslint-disable no-console */
/* eslint-disable unicorn/prefer-module */
import { useEffect, useState, useMemo } from "react";
import { Container, Loader } from "../../components";
import { useAuthContext } from "../../context/auth";
import { getEdificiosOperativos, getCategorias } from "../../utils";
import "./styles.scss";

function Vetos() {
  const [edificios, setEdificios] = useState();
  const [categorias, setCategorias] = useState([]);
  const [selectedCategoria, setSelectedCategoria] = useState(""); // Estado para la categoría seleccionada

  const { pais } = useAuthContext();

  useEffect(() => {
    const initialize = async () => {
      const newEdificios = await getEdificiosOperativos({ pais });
      setEdificios(newEdificios);
    };

    initialize();
  }, [pais]);

  useEffect(() => {
    const initialize = async () => {
      const newCategorias = await getCategorias();
      setCategorias(newCategorias);
    };

    initialize();
  }, []);

  // Filtra los edificios según la categoría seleccionada
  const filteredEdificios = useMemo(() => {
    if (!edificios) return [];
    return [...edificios]
      .filter(({ vetos }) => {
        if (!selectedCategoria) return true; // Mostrar todos si no hay categoría seleccionada
        return vetos && vetos.toUpperCase().includes(selectedCategoria.toUpperCase());
      })
      .sort((a, b) => a.edificio.localeCompare(b.edificio));
  }, [edificios, selectedCategoria]);

  // const calculateVetosCantidad = (vetos) => {
  //   if (!vetos) {
  //     return 0;
  //   }

  //   if (vetos.toUpperCase() === "NO TIENE VETOS") {
  //     return 0;
  //   }

  //   const empresasArray = vetos.split(",");
  //   return empresasArray.length;
  // };

  return (
    <Container className="Admin" header titulo="VETOS DE ANUNCIANTES POR EDIFICIO">
      {edificios ? (
        <>
          {/* Dropdown centrado con ancho al 50% */}
          <div style={{ display: "flex", justifyContent: "center", width: "100%", marginBottom: "20px" }}>
            <select
              id="categoriasDropdown"
              className="form-select"
              value={selectedCategoria}
              onChange={(e) => setSelectedCategoria(e.target.value)}
              style={{
                width: "20%", // El dropdown ocupa el 50% del contenedor
                maxWidth: "50%", // Asegura que no se expanda más del 50%
                padding: "8px",
                fontSize: "1rem",
                textAlign: "center",
              }}
            >
              <option value="">Seleccionar Categoría</option>
              {categorias.map((categoria) => (
                <option key={categoria.id} value={categoria.nombre}>
                  {categoria.nombre}
                </option>
              ))}
            </select>
          </div>

          {/* Tabla con estilo personalizado */}
          <div className="table-responsive mx-auto" style={{ width: "75%" }}>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="table-dark align-middle">
                <tr>
                  <th scope="col" style={{ width: "25%" }}>
                    EDIFICIO
                  </th>
                  <th scope="col" className="text-center" style={{ width: "65%" }}>
                    VETO
                  </th>
                </tr>
              </thead>
              <tbody className="table-striped align-middle">
                {filteredEdificios.map(({ id, edificio, vetos }) => (
                  <tr key={id}>
                    <td className="text-start">{edificio.toUpperCase()}</td>
                    <td className="text-center">{vetos}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </Container>
  );
}

export default Vetos;
