/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Container } from "..";

import { useAuthContext } from "../../context/auth";
import { getEdificios, updateEdificio, saveEdificio, getCategorias } from "../../utils";

import "./styles.scss";

const EdificiosTable = ({ pais: propPais }) => {
  const [categorias, setCategorias] = useState();
  const [edificios, setEdificios] = useState([]);
  const [editMode, setEditMode] = useState(null); // Estado para controlar el modo edición
  const [editedEdificio, setEditedEdificio] = useState(null); // Estado para almacenar los datos editados
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newEdificio, setNewEdificio] = useState({
    edificio: "",
    estado: "",
    alianza: "",
    personas: "",
    pantallas: "",
    totems: "",
    espera: "",
    programatica: "Sí",
    estacionamientos: "",
    estacionamientos_ocupacion: "",
    ubicacion: "",
    direccion: "",
    lat: "",
    lng: "",
    empresaslistado: "",
    empresasdestacadas: "",
    vetos: "",
    comision_1_porcentaje: "",
    comision_1_fechainicio: "",
    comision_1_fechafin: "",
  }); // Estado para almacenar los datos del nuevo edificio
  const [showForm, setShowForm] = useState(false); // Estado para controlar la visibilidad del formulario

  const { pais, rol } = useAuthContext(); // Extraer el rol del contexto

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  // Función reutilizable para obtener edificios y ordenarlos
  const fetchEdificios = async () => {
    try {
      const data = await getEdificios({ pais: propPais || pais });

      // Ordenar los edificios primero por estado y luego por cantidad de personas
      const sortedData = data.sort((a, b) => {
        const estadosOrder = { OPERATIVO: 1, INSTALACIÓN: 2, PROYECTO: 3 };
        if (estadosOrder[a.estado] !== estadosOrder[b.estado]) {
          return estadosOrder[a.estado] - estadosOrder[b.estado];
        }
        return b.personas - a.personas;
      });

      setEdificios(sortedData);
    } catch (err) {
      setError(err.message);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Inicializa categorías y edificios
  useEffect(() => {
    const initialize = async () => {
      const newCategorias = await getCategorias();
      setCategorias(newCategorias);
    };

    initialize();
  }, [pais]);

  // Usa fetchEdificios para cargar datos iniciales
  useEffect(() => {
    fetchEdificios();
  }, [propPais, pais]);

  const handleEditClick = (edificio) => {
    setEditMode(edificio.id);
    setEditedEdificio({
      ...edificio,
      vetos: edificio.vetos ? edificio.vetos.split(",").map((veto) => veto.trim()) : [], // Convierte a arreglo
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedEdificio({ ...editedEdificio, [name]: value }); // Actualiza los datos editados
  };

  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setNewEdificio({ ...newEdificio, [name]: value });
  };

  const handleAddEdificio = async () => {
    if (!newEdificio.edificio || !newEdificio.estado) {
      toast.error("Nombre y estado son obligatorios.");
      return;
    }

    try {
      const nuevo = {
        ...newEdificio,
        pais, // Agregar el país al objeto del edificio
      };

      // Guardar el edificio en la base de datos
      const savedEdificio = await saveEdificio(nuevo);

      // Agregar el nuevo edificio a la lista local
      setEdificios((prev) => [...prev, savedEdificio]);

      // Limpiar el formulario
      setNewEdificio({
        edificio: "",
        estado: "",
        alianza: "",
        personas: "",
        pantallas: "",
        totems: "",
        espera: "",
        programatica: "Sí",
        estacionamientos: "",
        estacionamientos_ocupacion: "",
        ubicacion: "",
        direccion: "",
        lat: "",
        lng: "",
        empresaslistado: "",
        empresasdestacadas: "",
        vetos: "",
        comision_1_porcentaje: "",
        comision_1_fechainicio: "",
        comision_1_fechafin: "",
      });

      toast.success("Edificio agregado exitosamente.");
    } catch (error) {
      console.error("Error al guardar el edificio:", error);
      toast.error("Error al agregar el edificio.");
    }
  };

  const handleSaveClick = async () => {
    try {
      setLoading(true);
  
      const formattedPersonas =
        typeof editedEdificio.personas === "string"
          ? parseInt(editedEdificio.personas.replace(/,/g, ""), 10)
          : editedEdificio.personas;
  
      await updateEdificio({
        id: editedEdificio.id,
        personas: formattedPersonas,
        vetos: editedEdificio.vetos,
        empresaslistado: editedEdificio.empresaslistado,
        empresasdestacadas: editedEdificio.empresasdestacadas,
      });
  
      toast.success("Edificio actualizado exitosamente");
  
      setTimeout(async () => {
        await fetchEdificios();
      }, 3000);
    } catch (error) {
      console.error("Error al actualizar el edificio:", error);
      toast.error("Error al actualizar el edificio");
    } finally {
      setLoading(false);
      setEditMode(null);
      setEditedEdificio(null);
    }
  };  

  const handleCancelClick = () => {
    setEditMode(null); // Sal del modo edición sin guardar
    setEditedEdificio(null);
  };

  if (loading) return <div>Cargando edificios...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleVetosChange = (categoriaNombre) => {
    setEditedEdificio((prev) => {
      const vetos = prev.vetos || [];
      if (vetos.includes(categoriaNombre)) {
        return {
          ...prev,
          vetos: vetos.filter((nombre) => nombre !== categoriaNombre),
        };
      }
      return {
        ...prev,
        vetos: [...vetos, categoriaNombre],
      };
    });
  };

  return (
    <Container className="oportunidades" header titulo="EDITAR INVENTARIO EDIFICIOS">
      {/* Formulario para agregar un nuevo edificio */}
      <div style={{ maxWidth: "95%", margin: "0 auto" }}>
        {rol === "superadmin" && (
          <div className="text-center mb-3">
            <button
              className={`btn ${showForm ? "btn-warning" : "btn-primary"}`}
              onClick={() => setShowForm((prev) => !prev)}
            >
              {showForm ? "Cancelar" : "Agregar Edificio"}
            </button>
          </div>
        )}

        {showForm && (

          <div className="row g-3 justify-content-center text-center">
            <div className="col-md-3">
              <input
                type="text"
                name="edificio"
                placeholder="Nombre"
                value={newEdificio.edificio}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="text"
                name="estado"
                placeholder="Estado"
                value={newEdificio.estado}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="text"
                name="alianza"
                placeholder="Propietario"
                value={newEdificio.alianza}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="number"
                name="personas"
                placeholder="Personas"
                value={newEdificio.personas}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="number"
                name="pantallas"
                placeholder="Pantallas"
                value={newEdificio.pantallas}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="number"
                name="totems"
                placeholder="Tótems"
                value={newEdificio.totems}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="number"
                name="espera"
                placeholder="Espera"
                value={newEdificio.espera}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="number"
                name="estacionamientos"
                placeholder="Estacionamientos"
                value={newEdificio.estacionamientos}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="number"
                name="estacionamientos_ocupacion"
                placeholder="Ocupación estacionamientos"
                value={newEdificio.estacionamientos_ocupacion}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="text"
                name="ubicacion"
                placeholder="Ubicación"
                value={newEdificio.ubicacion}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="text"
                name="direccion"
                placeholder="Dirección"
                value={newEdificio.direccion}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="number"
                name="lat"
                placeholder="Latitud"
                value={newEdificio.lat}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="number"
                name="lng"
                placeholder="Longitud"
                value={newEdificio.lng}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="text"
                name="empresaslistado"
                placeholder="Empresas listado"
                value={newEdificio.empresaslistado}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>     
            <div className="col-md-3">
              <input
                type="text"
                name="empresasdestacadas"
                placeholder="Empresas destacadas"
                value={newEdificio.empresasdestacadas}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="text"
                name="vetos"
                placeholder="Vetos"
                value={newEdificio.vetos}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="number"
                name="comision_1_porcentaje"
                placeholder="Comisión (%)"
                value={newEdificio.comision_1_porcentaje}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="date"
                name="comision_1_fechainicio"
                placeholder="Inicio contrato"
                value={newEdificio.comision_1_fechainicio}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <input
                type="date"
                name="comision_1_fechafin"
                placeholder="Fin contrato"
                value={newEdificio.comision_1_fechafin}
                onChange={handleNewInputChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-12 text-center mb-3">
              <button className="btn btn-primary" onClick={handleAddEdificio}>
      Agregar
              </button>
            </div>
          </div>
        )}
      </div>
      
      <div style={{ maxWidth: "95%", margin: "0 auto" }}>
        <table className="table table-bordered table-striped table-hover small text-sm">
          <thead>
            <tr className="table-dark text-center">
              <th width="3%">ID</th>
              <th width="7%">Nombre</th>
              <th width="5%">Status</th>
              <th width="5%">Personas</th>
              <th width="30%">Empresas</th>
              <th width="25%">Empresas destacadas</th>
              <th width="15%">Vetos</th>
              <th width="10%">Acción</th>
            </tr>
          </thead>
          <tbody>
            {edificios.map((edificio) => (
              <tr key={edificio.id}>
                {editMode === edificio.id ? (
                  <>
                    <td>{edificio.id}</td>
                    <td style={{ textAlign: "left" }}>
                      {edificio.edificio ? edificio.edificio.toUpperCase() : "-"}
                    </td>
                    <td
                      style={{
                        backgroundColor:
          edificio.estado === "OPERATIVO"
            ? "green"
            : edificio.estado === "INSTALACIÓN"
              ? "red"
              : edificio.estado === "PROYECTO"
                ? "blue"
                : "black",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      {edificio.estado}
                    </td>
                    <td>
                      <input
                        type="text"
                        name="personas"
                        value={editedEdificio.personas.toLocaleString(localString(pais), {
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                        })}
                        onChange={handleInputChange}
                        style={{
                          height: "20px",
                          width: "80px",
                          textAlign: "right",
                        }}
                      />
                    </td>
                    <td>
                      <textarea
                        name="empresaslistado"
                        value={editedEdificio.empresaslistado || ""}
                        onChange={(e) =>
                          setEditedEdificio((prev) => ({
                            ...prev,
                            empresaslistado: e.target.value.replace(/\n/g, ""), // Elimina cualquier salto de línea
                          }))
                        }
                        style={{
                          height: "100px", // Ajusta la altura
                          width: "100%", // Asegura que el campo ocupe todo el ancho
                          resize: "none", // Opcional: desactiva el redimensionamiento manual
                          textTransform: "uppercase", // Visualmente, convierte todo a mayúsculas
                        }}
                        className="form-control form-control-sm"
                      />
                    </td>
                    <td>
                      <textarea
                        name="empresasdestacadas"
                        value={editedEdificio.empresasdestacadas || ""}
                        onChange={(e) =>
                          setEditedEdificio((prev) => ({
                            ...prev,
                            empresasdestacadas: e.target.value.replace(/\n/g, ""), // Elimina cualquier salto de línea
                          }))
                        }
                        style={{
                          height: "100px", // Ajusta la altura
                          width: "100%", // Asegura que el campo ocupe todo el ancho
                          resize: "none", // Opcional: desactiva el redimensionamiento manual
                          textTransform: "uppercase", // Visualmente, convierte todo a mayúsculas
                        }}
                        className="form-control form-control-sm"
                      />
                    </td>

                    <td>
                      {editMode === edificio.id ? (
                        <div className="dropdown">
                          <button
                            className="btn btn-warning btn-sm dropdown-toggle"
                            type="button"
                            id={`dropdownVetos-${editedEdificio.id}`}
                            data-bs-toggle="dropdown"
                            style={{ fontSize: "11px" }}
                            aria-expanded="false"
                          >
            EDITAR
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby={`dropdownVetos-${editedEdificio.id}`}
                          >
                            {categorias?.map((categoria) => (
                              <li key={categoria.id} className="dropdown-item">
                                <input
                                  type="checkbox"
                                  id={`categoria-${categoria.id}`}
                                  checked={editedEdificio.vetos?.includes(categoria.nombre)}
                                  onChange={() => handleVetosChange(categoria.nombre)}
                                />
                                <label
                                  htmlFor={`categoria-${categoria.id}`}
                                  className="ms-2"
                                >
                                  {categoria.nombre}
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        edificio.vetos
                          ?.map((vetoId) => categorias?.find((cat) => cat.id === vetoId)?.nombre || "-")
                          .join(", ") || "-"
                      )}
                    </td>
                    <td>
                      <div className="d-flex flex-column align-items-center">
                        <button
                          onClick={handleSaveClick}
                          className="btn btn-success btn-sm"
                          style={{ fontSize: "11px", marginBottom: "5px", width: "100%" }}
                        >
          GUARDAR
                        </button>
                        <button
                          onClick={handleCancelClick}
                          className="btn btn-danger btn-sm"
                          style={{ fontSize: "11px", width: "100%" }}
                        >
          CANCELAR
                        </button>
                      </div>
                    </td>
                  </>
                ) : (
                  <>
                    <td>{edificio.id}</td>
                    <td style={{ textAlign: "left" }}>
                      {edificio.edificio ? edificio.edificio.toUpperCase() : "-"}
                    </td>
                    <td
                      style={{
                        backgroundColor:
          edificio.estado === "OPERATIVO"
            ? "green"
            : edificio.estado === "INSTALACIÓN"
              ? "red"
              : edificio.estado === "PROYECTO"
                ? "blue"
                : "black",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      {edificio.estado}
                    </td>
                    <td>
                      {edificio.personas
                        ? edificio.personas.toLocaleString(localString(pais), {
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                        })
                        : "-"}
                    </td>
                    <td>{(edificio.empresaslistado || "-").toUpperCase()}</td>
                    <td>{(edificio.empresasdestacadas || "-").toUpperCase()}</td>
                    <td>{edificio.vetos ? edificio.vetos : "-"}</td>
                    <td>
                      <button
                        onClick={() => handleEditClick(edificio)}
                        className="btn btn-primary"
                        style={{ padding: "3px 6px", fontSize: "10px" }}
                      >
        EDIT
                      </button>
                    </td>
                  </>
                )}

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Container>
  );
};

export default EdificiosTable;
