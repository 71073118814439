import { log, supabase } from ".";

export const saveEdificio = async (edificio) => {
  try {
    // Llama al método `insert` de `supabase` para insertar el edificio
    const data = await supabase.insert("edificios", edificio);

    // Validación de la respuesta
    if (!data || data.length === 0) {
      throw new Error("Empty response");
    }

    // Devuelve los datos insertados
    return data;
  } catch (error) {
    // Loguea cualquier error para depuración
    log.error("saveEdificio", error);
    throw error;
  }
};
